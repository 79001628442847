.wrapper .text-inner {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.table td, .table th {
    border: unset;
    vertical-align: middle;
    padding: .2em .4em;
}

.table td {
    color: #C8b386;
    font-size: 15px;
}