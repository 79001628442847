.margin-auto {
    margin: auto;
}

.card-content {
    background-color: unset;
}

.card {
    background-color: unset;
}

.privImage {
    /* border: 2px solid #C8b386; */
    width: 23vw;
    display: block;
    margin: 1px
}

.SuspenseImgContainer {
    display: inline-block;
    position: relative;
}

.moreBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #C8b386;
    width: 100%;
    text-align: center;
    padding-top: 34%;
    font-size: 1.3rem;
    font-weight: bold;
}

.privRowContainer {
    text-align: center;
}

.privRowContainer .SuspenseImgContainer:last-child img {
    background-color: rgba(255, 255, 255, 0.7);
}