.navbar-item img {
    max-height: 4rem;
}

.navbar-brand {
    height: 100%;
}

.footer-icon {
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

.homeIcon {
    background-image: url("/assets/buttons/Home-Gray.svg");
}

.active .homeIcon {
    background-image: url("/assets/buttons/Home-Gold.svg");
}

.privIcon {
    background-image: url("/assets/buttons/Privilege-Gray.svg");
}

.active .privIcon {
    background-image: url("/assets/buttons/Privilege-Gold.svg");
}

.activIcon {
    background-image: url("/assets/buttons/ACTIVITY-Gray.svg");
}

.active .activIcon {
    background-image: url("/assets/buttons/ACTIVITY-GOLD.svg");
}

.memberIcon {
    background-image: url("/assets/buttons/Member-Gray.svg");
}

.active .memberIcon {
    background-image: url("/assets/buttons/Member-Gold.svg");
}